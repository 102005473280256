<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    width="1200px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    style="height=1062px !important; max-height=1062px !important;"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            v-if="loadingAvulso"
            class="headline"
          >Aguarde carregando Associados...</span>
          <span
            v-else
            class="headline"
          >{{ formTitle }}</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        outlined
      >
        <base-loading v-if="loadingAvulso" />
        <div v-else>
          <v-form
            ref="form"
            dense
            lazy-validation
          >
            <v-row>
              <v-col>
                <v-card
                  elevation="0"
                >
                  <v-row>
                    <v-col
                      cols="6"
                      md="2"
                      align-self="center"
                    >
                      <data-field
                        v-model="data_referencia"
                        label="Referência*"
                        :mes-ano="true"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="2"
                      align-self="center"
                    >
                      <data-field
                        v-model="data_lancamento"
                        label="Data Lançamento*"
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      align-self="center"
                    >
                      <v-autocomplete
                        v-model="evento_id"
                        :items="loadedEvento"
                        item-text="descricao"
                        item-value="id"
                        dense
                        label="Selecione o Evento*"
                        no-data-text="Não há Eventos disponíveis"
                        clearable
                        :loading="!loadedEvento"
                        :rules="[rules.required]"
                        autocomplete="nope"
                        validate-on-blur
                        outlined
                        rounded
                        hide-details
                      >
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          <v-list-item-content
                            style="max-height: 100px"
                            class="overflow-y-auto"
                          >
                            <v-list-item-title>{{ data.item.codigo + '  -  ' + data.item.descricao }}</v-list-item-title>
                            <span
                              v-if="data.item.disabled"
                              color="red"
                            ><b><i>Este Evento já está lançado</i></b></span>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <base-vuetify-money
                        v-model="valor_total"
                        :options="money"
                        maxlength="14"
                        :rules="[rules.required]"
                        label="Valor Total"
                        validate-on-blur
                        dense
                        outlined
                        hide-details
                        rounded
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      align-self="center"
                    >
                      <v-autocomplete
                        v-model="fornecedor_id"
                        :items="loadedFornecedor"
                        item-text="razao_social"
                        item-value="id"
                        dense
                        label="Fornecedor*"
                        clearable
                        :loading="!loadedFornecedor"
                        :rules="[rules.minLength(1, '* Obrigatório')]"
                        autocomplete="nope"
                        validate-on-blur
                        outlined
                        rounded
                        hide-details
                      >
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          <v-list-item-content
                            style="max-height: 100px"
                            class="overflow-y-auto"
                          >
                            <v-list-item-title>{{ data.item.razao_social }}</v-list-item-title>
                            <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.nome_fantasia ) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      align-self="center"
                    >
                      <v-text-field
                        v-model="descricao"
                        label="Descrição do lançamento"
                        validate-on-blur
                        outlined
                        rounded
                        dense
                        :rules="[rules.maxLength(100, 'Max. 100 caracteres')]"
                        maxlength="100"
                      />
                    </v-col>
                    <v-card
                      outlined
                      elevation="0"
                      width="100%"
                      style="border-radius: 20px"
                    >
                      <crud-list
                        v-model="lancamentos"
                        :headers="headersLanc"
                        :actions="actionsLanc"
                        :sort-desc="[false]"
                        :item-height="$vuetify.breakpoint.xl ? '290' : '140'"
                        show-expand
                        style="max-width: 1354px !important;"
                        :slots="['item.valor_lancamento']"
                        class="pt-2 elevation-0"
                        :export-pdf-xlsx="true"
                      >
                        <template v-slot:[`item.valor_lancamento`]="{ item }">
                          <span>
                            {{ `${currencyFormatter(parseFloat(item.valor_lancamento), 2)}` }}
                          </span>
                        </template>
                        <template v-slot:btnCadastro>
                          <v-col
                            cols="12"
                            md="9"
                            class="ml-0 mr-0 mb-0"
                          >
                            <v-autocomplete
                              v-model="escolheAssociados"
                              :items="associadosDisponiveis()"
                              item-text="razao_social"
                              item-value="id"
                              return-object
                              label="Selecione o Associado para ser adicionado*"
                              no-data-text="Não há Associados disponíveis"
                              clearable
                              autocomplete="autocomplete_off_hack_xfr4!k"
                              validate-on-blur
                              style="width:900px"
                              outlined
                              rounded
                              :disabled="evento_id === '' || evento_id === null"
                              dense
                              hide-details
                            >
                              <template
                                slot="item"
                                slot-scope="data"
                              >
                                <v-list-item-content
                                  style="max-height: 100px"
                                  class="overflow-y-auto"
                                >
                                  <v-list-item-title>{{ data.item.razao_social }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.nome_fantasia ) }}</v-list-item-subtitle>
                                  <span
                                    v-if="data.item.disabled"
                                    color="red"
                                  ><b><i>Este Associado já está lançado</i></b></span>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <base-vuetify-money
                              v-model="valor_lanc"
                              :options="money"
                              maxlength="14"
                              label="Valor Lançamento*"
                              :disabled="validaCampos()"
                              validate-on-blur
                              dense
                              outlined
                              rounded
                              hide-details
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="10"
                            lg="11"
                          >
                            <v-textarea
                              v-model="observacaoAssociado"
                              label="Observação do Associado"
                              validate-on-blur
                              outlined
                              rounded
                              :disabled="evento_id === '' || evento_id === null"
                              hide-details
                              height="65"
                              no-resize
                              counter="255"
                              maxlength="255"
                            />
                          </v-col>
                          <v-col
                            sm="2"
                            lg="1"
                            class="text-center"
                          >
                            <v-btn
                              v-if="$vuetify.breakpoint.smAndUp"
                              color="success"
                              fab
                              title="Adiciona os Associado."
                              class="text-none text-white mb-2"
                              @click="adicionaAssociado()"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                            <v-btn
                              v-else
                              color="success"
                              rounded
                              title="Adiciona os Associado."
                              class="text-none text-white mb-2"
                              @click="adicionaAssociado()"
                            >
                              <v-icon>mdi-download</v-icon>
                              Adiciona Associado
                            </v-btn>
                          </v-col>
                        </template>
                        <template v-slot:expanded-item="{ item }">
                          <td
                            v-if="item.observacao !== ''"
                            :colspan="headersLanc.length"
                          >
                            <v-simple-table
                              :height="$vuetify.breakpoint.xl ? '90' : '90'"
                              fixed-header
                              class="my-1"
                              style="max-width: 1300px !important"
                              dense
                            >
                              <tbody>
                                <tr>
                                  <td
                                    class="text-left"
                                    style="max-width: 1298px;white-space: pre-wrap;"
                                  >
                                    <span
                                      style="font-weight: bold;"
                                      class="mb-0 pb-0"
                                    >
                                      Observação do Associado:
                                    </span>
                                    <span class="mb-1">{{ item.observacao }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </template>
                      </crud-list>
                    </v-card>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions
        class="justify-end mt-0"
      >
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="success"
          depressed
          rounded
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import { SUBMIT, CLOSE, SET_STATE } from '@/store/modules/forms/lancamentoAvulso'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import Swal from 'sweetalert2'

  export default {
    name: 'LancamentoForm',
    data () {
      return {
        rules,
        currencyFormatter,
        valid: false,
        snackbar: { message: '', show: false, color: 'error' },
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        escolheAssociados: null,
        valor_lanc: 0,
        observacaoAssociado: '',
        headersLanc: [
          { text: 'Ações', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '40px' },
          { align: 'right', sortable: true, groupable: false, floatingfilter: true, text: 'Código', value: 'associado.codigo', width: '20', report: true, styleReport: { halign: 'right' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Associado', value: 'associado.razao_social', width: 'auto', report: true },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor Lançamento', value: 'valor_lancamento', width: 'auto', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
        ],
        actionsLanc: [
          {
            title: 'Excluir Associado',
            color: 'red darken-3',
            click: item => this.removerAssociado(item),
            icon: 'mdi-trash-can-outline',
          },
        ],
      }
    },
    computed: {
      ...mapState('form/lancamentoAvulso', ['dialog', 'loadingAvulso', 'isEditing', 'loadedAssociado', 'loadedEvento', 'loadedFornecedor']),
      ...mapFields('form', [
        'data.id',
        'data.data_referencia',
        'data.data_lancamento',
        'data.evento_id',
        'data.descricao',
        'data.valor_total',
        'data.lancamentos',
        'data.fornecedor_id',
      ]),
      formTitle () {
        return !this.isEditing ? 'Incluir Lançamento Avulso' : 'Editar Lançamento Avulso'
      },
      vlr_lancamento () {
        let total = 0
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { total = (total + parseFloat(associado.valor_lancamento)) })
        return total.toFixed(2)
      },
    },
    methods: {
      ...mapMutations('form/lancamentoAvulso', [CLOSE, SET_STATE]),
      ...mapActions('form/lancamentoAvulso', [SUBMIT]),

      cancel () {
        this.CLOSE()
      },
      validaCampos () {
        if (this.$store.state?.form?.data?.evento_id === '') {
          return true
        }

        return false
      },
      associadosDisponiveis () {
        const associado_disponiveis = this.loadedAssociado
        return associado_disponiveis
      },
      adicionaAssociado () {
        if (this.escolheAssociados === null || this.escolheAssociados === '') {
          this.snackbar = {
            show: true,
            message: 'É necessário informar um Associado! Por favor, informe um Associado!',
            icon: 'success',
          }
          return null
        }

        if (this.valor_lanc === '') {
          this.valor_lanc = 0
        } else {
          this.valor_lanc = parseFloat(this.valor_lanc)
        }

        if (this.valor_lanc <= 0) {
          this.snackbar = {
            show: true,
            message: 'Não é permitido informar Associado sem um valor válido. Por favor, preencha-os campos!',
          }
          return null
        }

        const dadosGrid = this.$store.state.form.data.lancamentos.find(element => element.associado === this.escolheAssociados)

        if (dadosGrid) {
          dadosGrid.valor_lancamento += this.valor_lanc
        } else {
          this.$store.state.form.data.lancamentos.push({ associado: this.escolheAssociados, valor_lancamento: this.valor_lanc, observacao: this.observacaoAssociado })
        }

        this.escolheAssociados = {}
        this.valor_lanc = 0.00
        this.observacaoAssociado = ''
      },
      removerAssociado (item) {
        const index = this.$store.state.form.data.lancamentos.indexOf(item)
        this.$store.state.form.data.lancamentos.splice(index, 1)
      },
      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }

        if (this.$store.state.form.data.lancamentos.lenght < 1 || this.$store.state.form.data.lancamentos === undefined) {
          this.snackbar = {
            show: true,
            message: 'E necessário ter no mínimo 1 Associado com valores lançados. Por favor, lance um Associado!',
          }
          return null
        }

        if (parseFloat(this.$store.state?.form?.data?.valor_total) !== parseFloat(this.vlr_lancamento)) {
          Swal.fire({
            title: 'Atenção...',
            html: 'O Valor total informado diverge do valor cálculado! <br><br> Valor informado: ' + currencyFormatter(parseFloat(this.valor_total), 2) + '<br> Valor Calculado: ' + currencyFormatter(parseFloat(this.vlr_lancamento), 2) + ' <br><br> Por favor proceda com a correção.',
            icon: 'question',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Prosseguir sem corrigir',
            cancelButtonText: 'Retornar',
            focusConfirm: false,
            preConfirm: () => {
              this.SUBMIT({ router: this.$router, user: this.$user })
            },
          })
        } else {
          this.SUBMIT({ router: this.$router, user: this.$user })
        }
      },
    },
  }
</script>
<style lang="css">
.text-field-table.v-text-field--outline input {
    text-align: right !important;
}
.centered-input input {
      text-align: right;
      font-weight: bold;
    }
</style>
