export class LancamentoAvulsos {
  constructor ({
    id,
    evento_id,
    data_referencia,
    data_lancamento,
    descricao,
    valor_total,
    lancamentos,
    fornecedor_id,
  } = {
      id: '',
      evento_id: '',
      data_referencia: (new Date()).toISOString().slice(0, 10),
      data_lancamento: (new Date()).toISOString().slice(0, 10),
      descricao: '',
      valor_total: 0,
      lancamentos: [],
      fornecedor_id: '',
    }) {
    this.id = id
    this.evento_id = evento_id
    this.data_referencia = data_referencia
    this.data_lancamento = data_lancamento
    this.descricao = descricao
    this.valor_total = valor_total
    this.lancamentos = lancamentos
    this.fornecedor_id = fornecedor_id
  }
}

export function createLancamentoAvulsos (data) {
  return Object.freeze(new LancamentoAvulsos(data))
}
